import {analytics, auth} from 'auth/FirebaseAuth'
import {logEvent} from 'firebase/analytics'
import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import FirebaseService from 'services/FirebaseService'
import JwtAuthService from 'services/JwtAuthService'
import {
  authenticated,
  showAuthMessage,
  signInWithFacebookAuthenticated,
  signInWithGoogleAuthenticated,
  signOutSuccess,
  signUpSuccess
} from '../actions/Auth'
import {
  ACCESS_TOKEN,
  CHANGE_PASSWORD,
  COMPANIES,
  DELETE_USER,
  IS_DEMO,
  ROLES,
  SIGNIN,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_GOOGLE,
  SIGNOUT,
  SIGNUP,
  SOCIALS,
  UID
} from '../constants/Auth'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
    const {email, password} = payload
    try {
      const user = yield call(FirebaseService.signInEmailRequest, email, password)
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else {
        const accessToken = yield call([auth.currentUser, 'getIdToken'])
        localStorage.setItem(UID, user.user.uid)
        localStorage.setItem(ACCESS_TOKEN, accessToken)
        const info = yield call(JwtAuthService.getRole)
        localStorage.setItem(ROLES, JSON.stringify(info.data.roles))
        localStorage.setItem(COMPANIES, JSON.stringify(info.data.companies))
        localStorage.setItem(SOCIALS, JSON.stringify(info.data.socialsIds))
        localStorage.setItem(IS_DEMO, info.data.is_demo)
        yield put(authenticated(user.user.uid, info.data.roles, accessToken))
        logEvent(analytics, 'login')
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest)
      if (signOutUser === undefined) {
        JwtAuthService.removeTokens()
        yield put(signOutSuccess(signOutUser))
      } else {
        yield put(showAuthMessage(signOutUser.message))
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export function* deleteUser() {
  yield takeEvery(DELETE_USER, function* ({payload}) {
    try {
      yield call(FirebaseService.deleteUser, payload)
        .then(() => {
          console.log('User deleted!')
        })
        .catch((error) => {
          console.log(error)
        })
      JwtAuthService.removeTokens()
      yield put(signOutSuccess(undefined))
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
    const {email, password} = payload
    try {
      const user = yield call(FirebaseService.signUpEmailRequest, email, password)
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else {
        const accessToken = yield call([auth.currentUser, 'getIdToken'])
        localStorage.setItem(UID, user.user.uid)
        localStorage.setItem(ACCESS_TOKEN, accessToken)
        const info = yield call(JwtAuthService.getRole)
        localStorage.setItem(ROLES, JSON.stringify(info.data.roles))
        localStorage.setItem(COMPANIES, JSON.stringify(info.data.companies))
        localStorage.setItem(SOCIALS, JSON.stringify(info.data.socialsIds))
        localStorage.setItem(IS_DEMO, info.data.is_demo)
        yield put(signUpSuccess(user.user.uid, info.data.roles, accessToken))
      }
    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest)
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else {
        const accessToken = yield call([auth.currentUser, 'getIdToken'])
        const info = yield call(JwtAuthService.getRole)
        if (info.data.companies !== null || info.data.roles.includes('sysadmin')) {
          localStorage.setItem(UID, user.user.uid)
          localStorage.setItem(ACCESS_TOKEN, accessToken)
          localStorage.setItem(ROLES, JSON.stringify(info.data.roles))
          localStorage.setItem(COMPANIES, JSON.stringify(info.data.companies))
          localStorage.setItem(SOCIALS, JSON.stringify(info.data.socialsIds))

          localStorage.setItem(IS_DEMO, info.data.is_demo)
          yield put(signInWithGoogleAuthenticated(user.user.uid, info.data.roles, accessToken))
        } else {
          yield put(showAuthMessage("You don't have any access to the system, please contact your administrator"))
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(FirebaseService.signInFacebookRequest)
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else {
        const accessToken = yield call([auth.currentUser, 'getIdToken'])
        const info = yield call(JwtAuthService.getRole)
        if (info.data.companies !== null || info.data.roles.includes('sysadmin')) {
          localStorage.setItem(UID, user.user.uid)
          localStorage.setItem(ACCESS_TOKEN, accessToken)
          localStorage.setItem(ROLES, JSON.stringify(info.data.roles))
          localStorage.setItem(COMPANIES, JSON.stringify(info.data.companies))
          localStorage.setItem(SOCIALS, JSON.stringify(info.data.socialsIds))

          localStorage.setItem(IS_DEMO, info.data.is_demo)
          yield put(signInWithFacebookAuthenticated(user.user.uid, info.data.roles, accessToken))
        } else {
          yield put(showAuthMessage("You don't have any access to the system, please contact your administrator"))
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export function* changePassword() {
  yield takeEvery(CHANGE_PASSWORD, function* () {
    try {
      const res = yield call(FirebaseService.sendPasswordResetEmail)
      if (res === 'RESET_PASSWORD') {
        yield put(showAuthMessage(res))
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(deleteUser),
    fork(signUpWithFBEmail),
    fork(signInWithFBGoogle),
    fork(signInWithFacebook),
    fork(changePassword)
  ])
}
