import {deleteUser} from 'firebase/auth'
import {
  auth,
  createUserWithEmailAndPassword,
  facebookAuthProvider,
  googleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'auth/FirebaseAuth'

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) =>
  signInWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signOutRequest = async () =>
  signOut(auth)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signInGoogleRequest = async () =>
  signInWithPopup(auth, googleAuthProvider)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.signInFacebookRequest = async () => {
  try {
    const result = await signInWithPopup(auth, facebookAuthProvider)
    return result
  } catch (error) {
    throw error
  }
}

FirebaseService.signUpEmailRequest = async (email, password) =>
  createUserWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.deleteUser = async () =>
  deleteUser(auth.currentUser)
    .then((user) => user)
    .catch((err) => err)

FirebaseService.sendPasswordResetEmail = async () =>
  sendPasswordResetEmail(auth, auth.currentUser.email)
    .then(() => 'RESET_PASSWORD')
    .catch((err) => err)

export default FirebaseService
