import {
  AUTHENTICATED,
  CHANGE_PASSWORD,
  DELETE_USER,
  HIDE_AUTH_MESSAGE,
  SHOW_AUTH_MESSAGE,
  SHOW_LOADING,
  SIGNIN,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SIGNUP,
  SIGNUP_SUCCESS
} from '../constants/Auth'

export const signIn = (user) => ({
  type: SIGNIN,
  payload: user
})

export const authenticated = (token, role) => ({
  type: AUTHENTICATED,
  token,
  role
})

export const signOut = () => ({
  type: SIGNOUT
})

export const deleteUser = () => ({
  type: DELETE_USER
})

export const signOutSuccess = () => ({
  type: SIGNOUT_SUCCESS
})

export const signUp = (user) => ({
  type: SIGNUP,
  payload: user
})

export const signUpSuccess = (token, role) => ({
  type: SIGNUP_SUCCESS,
  token,
  role
})

export const signInWithGoogle = () => ({
  type: SIGNIN_WITH_GOOGLE
})

export const signInWithGoogleAuthenticated = (token, role) => ({
  type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  token,
  role
})

export const signInWithFacebook = () => ({
  type: SIGNIN_WITH_FACEBOOK
})

export const signInWithFacebookAuthenticated = (token, role) => ({
  type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  token,
  role
})

export const showAuthMessage = (message) => ({
  type: SHOW_AUTH_MESSAGE,
  message
})

export const hideAuthMessage = () => ({
  type: HIDE_AUTH_MESSAGE
})

export const showLoading = () => ({
  type: SHOW_LOADING
})

export const changePassword = () => ({
  type: CHANGE_PASSWORD
})
