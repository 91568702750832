import Icon, {MenuOutlined} from '@ant-design/icons'
import {Layout, Menu} from 'antd'
import {useEffect, useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import {LogoSVG} from '../../../assets/logos/icon'
import {APP_NAME, APP_PREFIX_PATH} from '../../../configs/AppConfig'
import utils from '../../../utils'
import CustomIcon from '../../util-components/CustomIcon'
import NavPanel from '../Header/NavPanel'

const {Sider} = Layout

export default function SiderNavBarApp({location, navConfig, toggleCollapsed, collapsed}) {
  const history = useHistory()
  const [scope, setScope] = useState(navConfig[1].key)
  const [currentNavKey, setCurrentNavKey] = useState(navConfig[1].submenu[0].key)
  const intl = useIntl()

  useEffect(() => {
    const currentRouteInfos = utils.getRouteInfo(navConfig, location.pathname)
    if (currentRouteInfos) {
      document.title = `${intl.formatMessage({
        id: currentRouteInfos.headerTitle
      })} - ${APP_NAME}`
      setScope(currentRouteInfos.parent ? currentRouteInfos.parent : currentRouteInfos)
      setCurrentNavKey(currentRouteInfos.key)
    } else {
      const scope = location.pathname.split('/')
      if (scope.length > 2) {
        setScope(scope[3])
        document.title = `${APP_NAME}`
      }
      setCurrentNavKey('')
    }
  }, [location])

  return (
    <>
      <Sider className="sider-app" collapsed={collapsed} width="240px">
        <div className="sider-title" style={{padding: 15}}>
          <span onClick={toggleCollapsed} className="burger-sidebar">
            <MenuOutlined style={{width: 20, height: 20}} />
          </span>
          <Link
            to={`${APP_PREFIX_PATH}/dashboard`}
            style={
              collapsed
                ? {display: 'none', transition: 'opacity 0.2s ease'}
                : {display: 'block', transition: 'opacity 1s ease'}
            }
          >
            {/* <Link to={navConfig[0].submenu.path || navConfig[0].path }> */}
            <CustomIcon svg={LogoSVG} />
            {/* </Link> */}
          </Link>
        </div>
        <div className="menu-sidebar">
          {scope && (
            <Menu
              mode="vertical"
              className="menu"
              selectedKeys={[currentNavKey]}
              style={{ fontWeight: '500', fontSize: '1.05rem', backgroundColor: 'transparent' }}
              {...( !collapsed && { openKeys: [scope] } )}
            >
              {navConfig.map((nav) =>
                nav.submenu.length > 0 &&
                nav.onHeader === true &&
                utils.checkUserAccess(nav.rights) &&
                ( utils.canSeeSocialPages(nav.socialIdentifier) || utils.checkUserAccess(['sysadmin']) ) ? (
                  nav.disableForDemo === true && utils.userIsDemo() ? (
                    <div key={nav.key}/>
                  ) : (
                    <Menu.SubMenu
                      key={nav.key}
                      title={intl.formatMessage({
                        id: nav.title
                      })}
                      mode={collapsed ? 'vertical' : 'inline'}
                      className="menu-submenu"
                      icon={<Icon component={nav.icon} className="icon"/>}
                      onTitleClick={() => {
                        setScope(nav.key);
                        if (nav.submenu.length) history.push(nav.submenu[0].path);
                      }}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      {nav.submenu.map((subNav) =>
                        subNav.onHeader === true &&
                        utils.checkUserAccess(subNav.rights) &&
                        ( !subNav.loopsiderOnly || utils.isFromLoopsider() || ( !subNav.betaTesters || utils.isBetaTester(subNav.betaTesters) ) ) &&
                        ( subNav.disableForDemo === true && utils.userIsDemo() ? (
                          <div key={subNav.key}/>
                        ) : (
                          <Menu.Item
                            key={subNav.key}
                            className="menu-item"
                            icon={<Icon component={subNav.icon} className="icon"/>}
                            style={{ backgroundColor: 'transparent' }}
                          >
                            <Link to={subNav.path} style={{ backgroundColor: 'transparent' }}>
                              <FormattedMessage id={subNav.title}/>
                            </Link>
                          </Menu.Item>
                        ) )
                      )}
                    </Menu.SubMenu>
                  )
                ) : nav.onHeader === true &&
                utils.checkUserAccess(nav.rights) &&
                utils.canSeeSocialPages(nav.socialIdentifier) ? (
                  <Menu.Item
                    key={nav.key}
                    className="menu-item menu-dashboard"
                    icon={<Icon component={nav.icon} className="icon"/>}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <Link to={nav.path}>
                      <FormattedMessage id={nav.title}/>
                    </Link>
                  </Menu.Item>
                ) : (
                  ''
                )
              )}
            </Menu>
          )}
        </div>
        <div className="sider-profile-panel">
          <NavPanel collapsed={collapsed} history={history} location={location}/>
        </div>
      </Sider>

      <style>
        {`
      .menu-sidebar::-webkit-scrollbar {
        display: none;
      }
      .menu ul {
        background-color: transparent!important;
      }
    `}
      </style>
    </>
  )
}
