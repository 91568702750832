import {env} from 'configs/EnvironmentConfig'
import {COMPANIES, IS_DEMO, ROLES} from '../redux/constants/Auth'
import {signOut} from 'redux/actions/Auth'

class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object|null} object that contains the path string
   */
  static getRouteInfo(navTree, path) {
    for (let i = 0; i < navTree.length; i++) {
      const branch = navTree[i]
      if (branch.path === path) {
        return branch
      }

      let route = null
      if (branch.submenu) {
        route = this.getRouteInfo(branch.submenu, path)
        if (route) {
          return route
        }
      }
    }
    return null
  }

  static flattenCategoriesRecursive(tree, prefix) {
    let rows = []
    tree.forEach((branch) => {
      rows.push({
        id: branch.id,
        name: prefix + branch.name
      })
      if (branch.children) {
        rows = [...rows, ...this.flattenCategoriesRecursive(branch.children, prefix + branch.name + ' > ')]
      }
    })
    return rows
  }

  static getBucketUrlLink = (path) => {
    return `${env.googleCdn}/default/link_display.jpg`
  }

  static getBucketUrl = (path) => {
    // Check if path is an object and has the property 'attachment_storage_thumbnail'
    const actualPath = typeof path === 'object' && path !== null ? path.attachment_storage_thumbnail : path

    if (
      actualPath &&
      (actualPath.startsWith('facebook/') ||
        actualPath.startsWith('tiktok/') ||
        actualPath.startsWith('youtube/') ||
        actualPath.startsWith('snapchat/') ||
        actualPath.startsWith('linkedin/') ||
        actualPath.startsWith('twitter/') ||
        actualPath.startsWith('benchmark/'))
    ) {
      return `${env.googleCdn}/${encodeURIComponent(actualPath)}`
    } else if (actualPath) {
      return `${env.facebookStorageBucketBaseUrl}/${actualPath}`
    } else {
      return `${env.googleCdn}/default/not-found.jpeg`
    }
  }

  static getBucketUrlDocs = (doc, lang) => {
    return `${env.googleCdn}/web/${lang}/docs/${doc}`
  }

  /**
   * We need to calculate the relation between the total of a metric and its benchmark
   * to display on several places
   *
   * @param data actual value for given metric
   * @param average benchmark expected for given metric
   * @returns
   */
  static relativity = (data, average) => {
    let relativity = null
    if (data && average) {
      data = parseFloat(data)
      average = parseFloat(average)
      relativity = data >= average ? data / average : -average / data
    }
    return relativity
  }

  static checkUserAccess = (rights) => {
    if (!rights) {
      return true
    }
    const userRights = localStorage.getItem(ROLES)
    if (!userRights) {
      return false
    }
    // TODO : Check if it is in array but not in the string
    return rights.some((right) => userRights.includes(right))
  }

  static checkUserCompanies = (compagnyId) => {}

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    const threshold = 130
    const hRed = hexToR(hex)
    const hGreen = hexToG(hex)
    const hBlue = hexToB(hex)
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16)
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16)
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16)
    }
    function cutHex(h) {
      return h.charAt(0) === '#' ? h.substring(1, 7) : h
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000
    if (cBrightness > threshold) {
      return 'dark'
    } else {
      return 'light'
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16)
    let G = parseInt(color.substring(3, 5), 16)
    let B = parseInt(color.substring(5, 7), 16)
    R = parseInt((R * (100 + percent)) / 100)
    G = parseInt((G * (100 + percent)) / 100)
    B = parseInt((B * (100 + percent)) / 100)
    R = R < 255 ? R : 255
    G = G < 255 ? G : 255
    B = B < 255 ? B : 255
    const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
    const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
    const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)
    return `#${RR}${GG}${BB}`
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive
    }
    if (number < 0) {
      return negative
    }
    return null
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
      return a[key] - b[key]
    }

    if (typeof a[key] === 'string' && typeof b[key] === 'string') {
      a = a[key].toLowerCase()
      b = b[key].toLowerCase()
      return a > b ? -1 : b > a ? 1 : 0
    }
    return
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list
    if (list) {
      data = list.filter((item) => item[key] === value)
    }
    return data
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list
    if (list) {
      data = list.filter((item) => item[key] !== value)
    }
    return data
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue
        }
        if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
          return true
        }
      }
    }
    list = list.filter((value) => searchText(value))
    return list
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = []
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key]
        if (element) {
          breakpoints.push(key)
        }
      }
    }
    return breakpoints
  }

  /**
   * This method is used to check if is user demo for platform audit and disable feature
   */
  static userIsDemo() {
    const is_demo = localStorage.getItem(IS_DEMO)
    if (is_demo === 'true') {
      return true
    } else {
      return false
    }
  }

  /**
   * This method is used to determine if a user can see some submenu of social pages
   */
  static canSeeSocialPages(socialIdentifier) {
    if (!socialIdentifier) {
      return true
    }
    const socials = localStorage.getItem('socials')
    if (!socials || socials === 'undefined' || socials === 'null') {
      signOut()
      return false
    }
    return socials.includes(socialIdentifier)
  }

  /**
   * This method is used to determine if a user can see some submenu of navigation side bar
   * aka if is his from loopsider or a client
   */

  static isFromLoopsider(retry) {
    if (this.checkUserAccess(['sysadmin'])) {
      return true
    }
    const userWorkspace = localStorage.getItem(COMPANIES)
    const envWorkspacesVal = env.loopsiderWorkspaces
    if (
      !userWorkspace ||
      userWorkspace === 'undefined' ||
      userWorkspace === 'null' ||
      !envWorkspacesVal ||
      envWorkspacesVal === 'undefined' ||
      envWorkspacesVal === 'null'
    ) {
      if (!retry) {
        setTimeout(() => {
          if (this.isFromLoopsider(true)) {
            window.location.reload()
          }
        }, 10)
      }
      return false
    }
    const envWorkspaces = JSON.parse(envWorkspacesVal)
    const userWorkspaces = JSON.parse(userWorkspace)
    return userWorkspaces.some((r) => envWorkspaces.includes(r))
  }

  static isBetaTester(betaTesters) {
    const userRights = localStorage.getItem(ROLES)

    /*if (userRights && userRights.includes('sysadmin')) {
      return true
    }*/
    // Vérifie si l'utilisateur est de Loopsider
   /* if (this.isFromLoopsider()) {
      return true
    }*/

    if (!betaTesters || betaTesters.length === 0) {
      return true // Si aucun beta tester spécifié, l'élément est visible par tous
    }

    const clientId = localStorage.getItem('client')

    // Vérifie si le clientId est dans la liste des betaTesters
    return betaTesters.includes(Number(clientId))
  }
}

export default Utils
