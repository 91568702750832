import {initializeApp} from 'firebase/app'
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getAnalytics} from 'firebase/analytics'
import firebaseConfig from 'configs/FirebaseConfig'

const app = initializeApp(firebaseConfig)

// Firebase utils
const db = getFirestore(app)
const auth = getAuth(app)
const googleAuthProvider = new GoogleAuthProvider()
const facebookAuthProvider = new FacebookAuthProvider()
facebookAuthProvider.addScope('read_insights')
const analytics = getAnalytics(app)

export {
  db,
  auth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  deleteUser,
  sendPasswordResetEmail,
  googleAuthProvider,
  facebookAuthProvider,
  analytics
}
