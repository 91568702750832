import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Menu, Dropdown} from 'antd'
import {useLanguage} from 'contexts/LanguageContext'
import {UpOutlined} from '@ant-design/icons'

const LanguageSwitcher = ({openDefault = true}) => {
  const intl = useIntl()
  const {locale, handleLanguageChange} = useLanguage()
  const [open, setOpen] = useState(openDefault)

  const handleClickOutside = () => {
    setOpen(false)
  }

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  const handleMenuClick = (e) => {
    handleLanguageChange(e.key)
    setOpen(false)
  }

  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={[locale]}>
      <Menu.Item key="en">
        <strong style={{color: 'black'}}>{intl.formatMessage({id: 'en'})}</strong>
      </Menu.Item>
      <Menu.Item key="fr">
        <strong style={{color: 'black'}}>{intl.formatMessage({id: 'fr'})}</strong>
      </Menu.Item>
    </Menu>
  )

  return (
    <div onClick={stopPropagation}>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={open}
        onVisibleChange={setOpen}
        placement="topRight"
        overlayStyle={{
          width: '195px'
        }}
      >
        <span
          onClick={handleClickOutside}
          id={locale}
          className="language-switcher-dropdown"
          style={{cursor: 'pointer'}}
        >
          {intl.formatMessage({id: locale})}
          <span id="dropdown-arrow">
            <UpOutlined />
          </span>
        </span>
      </Dropdown>
    </div>
  )
}

export default LanguageSwitcher
