const firebase = {
  apiKey: process.env.REACT_APP_FIREBASE_APP_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID
}

const facebook = {
  redirectionUri: process.env.REACT_APP_FB_REDIRECTION_URI,
  appId: process.env.REACT_APP_FB_APP_ID,
  fields: 'id,picture,name',
  scope: 'ads_read,instagram_basic,instagram_manage_insights,pages_read_engagement,pages_read_user_content,pages_show_list,read_insights,business_management',
  // scope: 'ads_management,ads_read,instagram_basic,instagram_manage_insights,pages_manage_ads,pages_manage_engagement,pages_manage_metadata,pages_manage_posts,pages_read_engagement,pages_read_user_content,pages_show_list,read_insights,business_management',
  scopeFbAds: 'ads_read,business_management'
  // scopeFbAds: 'ads_management,ads_read,business_management'
}

const google = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  redirectionUri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECTION_URI
}

const youtube = {
  clientId: process.env.REACT_APP_YOUTUBE_CLIENT_ID,
  redirectionUri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECTION_URI,
  // scope: 'openid https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.channel-memberships.creator https://www.googleapis.com/auth/youtube.force-ssl https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtubepartner-channel-audit https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/yt-analytics-monetary.readonly'
  scope: 'openid https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/yt-analytics.readonly https://www.googleapis.com/auth/yt-analytics-monetary.readonly'
}

const tiktok = {
  clientId: process.env.REACT_APP_TIKTOK_CLIENT_KEY,
  redirectionUri: process.env.REACT_APP_TIKTOK_REDIRECT_URL
}

const tiktokAds = {
  clientId: process.env.REACT_APP_TIKTOK_ADS_CLIENT_KEY,
  clientSeret: process.env.REACT_APP_TIKTOK_ADS_CLIENT_SECRET,
  clientCode: process.env.REACT_APP_TIKTOK_ADS_CLIENT_CODE,
  redirectionUri: process.env.REACT_APP_TIKTOK_ADS_REDIRECT_URL
}

const linkedin = {
  clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
  redirectionUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
  scope: 'r_basicprofile,r_organization_social,r_organization_social_feed'
}

const documentation = {
  baseUrl: 'https://sherpai.gitbook.io/sherpai'
}

const envDefault = {
  nodeEnv: process.env.REACT_APP_NODE_ENV,
  API_ENDPOINT_URL: process.env.REACT_APP_DOMAIN_API,
  firebase,
  facebook,
  google,
  youtube,
  tiktok,
  tiktokAds,
  linkedin,
  documentation,
  facebookStorageBucketBaseUrl: process.env.REACT_APP_GOOGLE_BUCKET_FACEBOOK_ATTACHMENTS,
  googleCdn: process.env.REACT_APP_GOOGLE_CDN,
  loopsiderWorkspaces: process.env.REACT_APP_LOOPSIDER_WORKSPACES
}

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return {...envDefault}
    case 'production':
      return {...envDefault}
    case 'test':
      return {...envDefault}
    case 'staging':
      return {...envDefault}
    default:
      break
  }
}

export const env = getEnv()
